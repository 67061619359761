import React from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import { $t } from "hooks/i18n";
import PageTitle from "components/page/page-title";
import PageAlert from "components/page/page-alert";
import FormSection from "components/page/form-section";
import { AuthTypes, ServiceAuthTypes, ServiceTypes, YesNoChoice } from "Constants";
import { ServiceService } from "services/service-service";
import { useParams, useNavigate } from "react-router-dom";
import { EditServiceRequest, ApplicationData, ServiceFileType, ServiceFile, ServiceRegField, ApplicationSamlConfigData, ServiceEmailConfig, Translations } from "types/edit-service-request";
import ApplicationRow from "pages/services/components/application-row";
import { hideValidationErrors, isEmpty, showError, showValidationErrors } from "../../hooks/helpers";
import { ClassifierService } from "services/classifier-service";
import { ServiceFileService } from "services/service-file-service";
import closeIcon from "assets/close.svg";
import ServiceRegFields from "../../components/service-reg-fields";
import { ClassifierDto } from "dto/classifier-dto";
import ConfirmModal from "components/modals/confirm-modal";
import { UserData } from "../../types/user-data";
import { UserRights } from "Constants";

type ServiceEditPageProps = {
    user: UserData
}

const ServiceEditPage = (props: ServiceEditPageProps) => {
	const [formData, setFormData] = React.useState<EditServiceRequest | null>(null);
	const [title, setTitle] = React.useState<string>("");
	const [opening, setOpening] = React.useState<Boolean>(true);
	const [localErrors, setLocalErrors] = React.useState<{ [key: string]: any }>({});
	const [confirmModal, setConfirmModal] = React.useState<{ [key: string]: any }>({ open: false, title: "", message: "" });
	const isAdmin = UserRights.isAdmin(props.user.rights);

	const [allClassifiers, setAllClassifiers] = React.useState<ClassifierDto[]>([]);

	let { id } = useParams();
	const navigate = useNavigate();

	const loadData = async () => {
		if (!id) {
			showError($t("Missing id parameter"));
			return;
		}
		const allclvs = await ClassifierService.fetchAllClassifiers();
		setAllClassifiers(allclvs);
		ServiceService.fetchService(id)
			.then((resp: any) => {
				resp.system_notification = (resp.system_notification ? JSON.parse(resp.system_notification) : {et: "", en: "", ru: ""}) as Translations;
				resp.info_notification = (resp.info_notification ? JSON.parse(resp.info_notification) : {et: "", en: "", ru: ""}) as Translations;
				setFormData(addDefaultEmailConfig(resp) as EditServiceRequest);
				setTitle((resp as EditServiceRequest).name);
				setOpening(false);
			})
			.catch(ex => showError(ex));
	}

  const addDefaultEmailConfig = (data : EditServiceRequest): any => {
      if(!data.email_config){
        data.email_config = {} as ServiceEmailConfig
      }
      return data;
  }
    const setField = (val: any, field: string) => {
        let tmp = { ...formData } as { [key: string]: any };
        tmp[field] = val;
        setFormData(tmp as EditServiceRequest);
    }
    const setBooleanField = (val: string, field: string) => {
        let tmp = { ...formData } as { [key: string]: any };
        tmp[field] = val === "true";
        setFormData(tmp as EditServiceRequest);
	}
    const setEmailField = (val: any, field: string) => {
        let tmp = { ...formData }
        let emailConfTmp = { ...tmp.email_config } as { [key: string]: any };
        emailConfTmp[field] = val;
        tmp.email_config = emailConfTmp as ServiceEmailConfig
        setFormData(tmp as EditServiceRequest);
	}
	const setTransField = (val: any, locale: string, field: string) => {
		let tmp = { ...formData } as { [key: string]: any };
		let valTmp = !tmp[field] ? {} : { ...tmp[field] };
		valTmp[locale] = val;
		tmp[field] = valTmp;
		setFormData(tmp as EditServiceRequest);
	}
    const setStringArr = (checked: boolean, field: string, auth_type: string) => {
        let tmp = { ...formData } as { [key: string]: any };
        let idx = tmp[field].indexOf(auth_type);
        if (checked && idx === -1) {
            tmp[field].push(auth_type);
        }
        else if (!checked && idx >= 0) {
            tmp[field].splice(idx, 1);
        }
        setFormData(tmp as EditServiceRequest);
    }
    const setNumberArr = (checked: boolean, field: string, num: number) => {
        let tmp = { ...formData } as { [key: string]: any };
        let idx = tmp[field].indexOf(num);
        if (checked && idx === -1) {
            tmp[field].push(num);
        }
        else if (!checked && idx >= 0) {
            tmp[field].splice(idx, 1);
        }
        setFormData(tmp as EditServiceRequest);
    }
    const setApplication = (i: number, app: ApplicationData) => {
        let tmp = { ...formData };
        if (tmp.applications !== undefined)
            tmp.applications[i] = app
        setFormData(tmp as EditServiceRequest);
    }
    const deleteApplication = async (i: number) => {
        let tmp = { ...formData };
        if (tmp.applications === undefined || tmp.applications.length < i + 1)
            return;
        let applicationId = tmp.applications[i].id;
        if (applicationId) {
            //todo: must be done on service save
            //if (!await ServicesService.deleteApplication(applicationId))
            //  return;
        }
        tmp.applications.splice(i, 1);
        setFormData(tmp as EditServiceRequest);
    }
  const addApplication = () => {
    let tmp = { ...formData };
    if (tmp.applications === undefined)
      tmp.applications = [];
    tmp.applications.push({
      id: 0,
      auth_type: ServiceAuthTypes[0].code,
      app_id: "",
      xml_param_name: "",
      servicename_param: "",
      return_url: "",
      unregister_url: "",
      unregistered_url: "",
      unregister_auth_header: "",
      sierra_code: "",
			logging_out: "",
			logging_url: "",
			is_mobile_app: false,
			auth_header_secret: "",
        application_saml_config_data: {
            binding: "",
            acs_location: "",
            nameid_format: "",
            logout_location: "",
            metadata_location: "",
            entity_id: ""
        } as ApplicationSamlConfigData
    });
    setFormData(tmp as EditServiceRequest);
  }
  const serviceRegFieldsChanged = (val: ServiceRegField[]) => {
    let tmp = { ...formData };
    tmp.service_reg_fields = val;
    setFormData(tmp as EditServiceRequest);
  }

  const validateEmailConfig = () : boolean => {
    if(formData?.email_config){
      const emailData = formData.email_config
      if(isEmpty(emailData.emailFromEmail) ||
         isEmpty(emailData.emailFromName) ||
         isEmpty(emailData.emailFromPassword) ||
         isEmpty(emailData.emailSmtp) ||
         isEmpty(emailData.emailSmtpPort)){
            return false;
         }else
            return true
    }
    return false;
  }
	const saveClick = (validateEmail: boolean) => {
		if (validateEmail) {
			if (!validateEmailConfig()) {
				openEmailConfigModal();
				return;
			}
		}
		if (!id || !formData) {
			return;
		}
		hideValidationErrors(localErrors, setLocalErrors);
		let data = { ...formData, system_notification: JSON.stringify(formData.system_notification), info_notification: JSON.stringify(formData.info_notification) } as { [key: string]: any };
		ServiceService.saveService(id, data)
			.then((resp) => {
				if (!showValidationErrors(resp, setLocalErrors)) {
					closeForm();
				}
			})
			.catch(ex => showError(ex));
	}

  const openEmailConfigModal = () => {
		setConfirmModal({ open: true, title: $t("Email modal header"), message: $t("Email modal text") });
	}

  const closeForm = () => {
    navigate("/services");
  }

  const getServiceFile = (fileType: string): ServiceFile | null => {
    if (formData == null)
      return null;
    const file = formData.service_files?.find(x => x.type === fileType);
    if (file != null)
      return file;
    return null;
  }
  const deleteServiceFile = async (serviceFileId: number) => {
    await ServiceFileService.deleteServicefile(serviceFileId);

    if (formData != null) {
      let tmp = { ...formData };
      tmp.service_files = formData!.service_files!.filter(x => x.id != serviceFileId);
      setFormData(tmp as EditServiceRequest);
    }
  }
  const downloadServiceFile = async (serviceFileId: number, fileName: string) => {
    await ServiceFileService.downloadServicefile(serviceFileId, fileName);
  }
  React.useEffect(() => {
    loadData();
  }, []);

    if (opening || formData == null) return null;

    const renderRegFields = () => {
        if (!formData.reg_enabled)
            return null;
        return <FormSection title={$t("Reg. form fields")} required={false}>
            <Row>
                <Col xs={12} className="mb-3">
                    <ServiceRegFields values={formData.service_reg_fields} change={(e) => serviceRegFieldsChanged(e)} />
                </Col>
            </Row>
        </FormSection>;
	}
    const renderTerms = () => {
        if (!formData.reg_enabled)
            return null;
        return <FormSection title={$t("Terms of usage")} required={false}>
            <Row>
                <Col xs={12} sm={6} md={4}>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>{$t("Service terms")}</Form.Label>
                        <div className="btn-float-cont">
                            <Form.Control value={formData.service_terms?.name} readOnly placeholder={$t("Choose PDF")} />
                            <div className="btn-float-right">
                                <div style={{ display: "none" }}><input formEncType="multipart/form-data" type="file" id="userfile9233" name="userfile9233"
                                    multiple={false} onChange={e => setField(e.target?.files![0], "service_terms")} /></div>
                                <label htmlFor="userfile9233" className="like-btn">
                                    {$t("Upload")}
                                </label>
                            </div>
                        </div>
                    </Form.Group>
                    {
                        getServiceFile(ServiceFileType.ServiceTerms) != null && <>
                            <label style={{ cursor: "pointer" }} onClick={() => { downloadServiceFile(getServiceFile(ServiceFileType.ServiceTerms)!.id, getServiceFile(ServiceFileType.ServiceTerms)!.name); }}>
                                {getServiceFile(ServiceFileType.ServiceTerms)?.name}
                            </label>
                            <Button variant="link" className="btn-icon" onClick={() => deleteServiceFile(getServiceFile(ServiceFileType.ServiceTerms)!.id)}>
                                <img src={closeIcon} />
                            </Button>
                        </>
                    }
                </Col>
                <Col xs={12} sm={6} md={4}>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>{$t("Privacy policy")}</Form.Label>
                        <div className="btn-float-cont">
                            <Form.Control value={formData.privacy_policy?.name} readOnly placeholder={$t("Choose PDF")} />
                            <div className="btn-float-right">
                                <div style={{ display: "none" }}><input formEncType="multipart/form-data" type="file" id="userfile9253" name="userfile9253"
                                    multiple={false} onChange={e => setField(e.target?.files![0], "privacy_policy")} /></div>
                                <label htmlFor="userfile9253" className="like-btn">
                                    {$t("Upload")}
                                </label>
                            </div>
                        </div>
                    </Form.Group>
                    {
                        getServiceFile(ServiceFileType.PrivacyPolicy) != null && <>
                            <label style={{ cursor: "pointer" }} onClick={() => { downloadServiceFile(getServiceFile(ServiceFileType.PrivacyPolicy)!.id, getServiceFile(ServiceFileType.PrivacyPolicy)!.name); }}>
                                {getServiceFile(ServiceFileType.PrivacyPolicy)?.name}
                            </label>
                            <Button variant="link" className="btn-icon" onClick={() => deleteServiceFile(getServiceFile(ServiceFileType.PrivacyPolicy)!.id)}>
                                <img src={closeIcon} />
                            </Button>
                        </>
                    }
                </Col>
            </Row>
        </FormSection>
	}
    const renderSysNotif = () => {
        if (!formData.reg_enabled)
			return null;

		/*
		{formData.all_locales.map(x => <Form.Check
										key={x.id}
										checked={formData.service_locales.indexOf(x.id) >= 0}
										type="switch"
										inline
										label={x.locale}
										onChange={e => setNumberArr(e.target.checked, "service_locales", x.id)}
									/>)}
		 * */
        return <FormSection title={$t("System notification")} required={false}>
            <Row>
                <Col xs={12} className="mb-3">
                    <Form.Check
                        checked={formData.system_notification_enabled}
                        type="switch"
                        inline
                        label={$t("Notification enabled")}
                        onChange={e => setField(e.target.checked, "system_notification_enabled")}
                    />
                </Col>
				{formData.all_locales.filter(x => formData.service_locales.indexOf(x.id) >= 0).map(x => <Col xs={12} key={x.locale}>
					<Form.Group className="mb-3" controlId="name">
						<Form.Label>{x.locale}</Form.Label>
						<Form.Control value={formData.system_notification ? formData.system_notification[x.locale as keyof Translations] : ""} onChange={e => setTransField(e.target.value, x.locale, "system_notification")} />
					</Form.Group>
				</Col>)}
            </Row>
        </FormSection>;
	}

	const renderInfoNotif = () => {
		if (!formData.reg_enabled)
			return null;
		
		return <FormSection title={$t("Info notification")} required={false}>
			<Row>
				<Col xs={12} className="mb-3">
					<Form.Check
						checked={formData.info_notification_enabled}
						type="switch"
						inline
						label={$t("Notification enabled")}
						onChange={e => setField(e.target.checked, "info_notification_enabled")}
					/>
				</Col>
				{formData.all_locales.filter(x => formData.service_locales.indexOf(x.id) >= 0).map(x => <Col xs={12} key={x.locale}>
					<Form.Group className="mb-3" controlId="name">
						<Form.Label>{x.locale}</Form.Label>
						<Form.Control value={formData.info_notification ? formData.info_notification[x.locale as keyof Translations] : ""} onChange={e => setTransField(e.target.value, x.locale, "info_notification")} />
					</Form.Group>
				</Col>)}
			</Row>
		</FormSection>;
	}

	return (<div>
		<PageTitle title={title}>
			<Button variant="secondary" onClick={() => closeForm()}>Loobu</Button>
			<Button variant="primary" onClick={() => saveClick(true)}>Salvesta</Button>
		</PageTitle>
		<PageAlert message="Teenuse üldandmed saab muuta vaid administraatori õigustes kasutaja" />
		<div>
			<Container>
				<Form>
					<FormSection title="Üldandmed" required={true}>
						<Row>
							<Col xs={12} sm={6} md={4}>
								<Form.Group className="mb-3" controlId="name">
									<Form.Label>{$t("Service name")}</Form.Label>
									<Form.Control value={formData.name} onChange={e => setField(e.target.value, "name")} isInvalid={!!localErrors.name} disabled={!isAdmin} />
									{!!localErrors.name ? <Form.Control.Feedback type="invalid">{localErrors.name}</Form.Control.Feedback> : null}
								</Form.Group>
							</Col>
							<Col xs={12} sm={6} md={4}>
								<Form.Group className="mb-3" controlId="serviceId">
									<Form.Label>{$t("Service ID")}</Form.Label>
									<Form.Control value={formData.id.toString()} disabled={true} />
								</Form.Group>
							</Col>
							<Col xs={12} sm={6} md={4}>
								<Form.Group className="mb-3" controlId="type">
									<Form.Label>{$t("Service type")}</Form.Label>
									<Form.Select value={formData.type} onChange={e => setField(e.target.value, "type")} isInvalid={!!localErrors.type} disabled={!isAdmin}>
										{ServiceTypes.map(x => <option key={x.name} value={x.name}>{x.title}</option>)}
									</Form.Select>
									{!!localErrors.type ? <Form.Control.Feedback type="invalid">{localErrors.type}</Form.Control.Feedback> : null}
								</Form.Group>
							</Col>
							<Col xs={12} sm={6} md={4}>
								<Form.Group className="mb-3" controlId="type">
									<Form.Label>{$t("Registration to service enabled")}</Form.Label>
									<Form.Select value={formData.reg_enabled.toString()} onChange={e => setBooleanField(e.target.value, "reg_enabled")} isInvalid={!!localErrors.reg_enabled} disabled={!isAdmin}>
										{YesNoChoice.map(x => <option key={x.value.toString()} value={x.value.toString()}>{x.text}</option>)}
									</Form.Select>
									{!!localErrors.reg_enabled ? <Form.Control.Feedback type="invalid">{localErrors.reg_enabled}</Form.Control.Feedback> : null}
								</Form.Group>
							</Col>
							<Col xs={12} sm={6} md={4}>
								<Form.Group className="mb-3" controlId="type">
									<Form.Label>{$t("Service design")}</Form.Label>
									<Form.Select value={formData.design} onChange={e => setField(e.target.value, "design")} isInvalid={!!localErrors.design} disabled={!isAdmin}>
										{ServiceTypes.map(x => <option key={x.name} value={x.name}>{x.title}</option>)}
									</Form.Select>
									{!!localErrors.design ? <Form.Control.Feedback type="invalid">{localErrors.design}</Form.Control.Feedback> : null}
								</Form.Group>
							</Col>
							<Col xs={12} sm={6} md={4}>
								<Form.Group className="mb-3" controlId="type">
									<Form.Label>Ainulogimine lubatud</Form.Label>
									<Form.Select value={formData.allow_sso.toString()} onChange={e => setBooleanField(e.target.value, "allow_sso")} isInvalid={!!localErrors.allow_sso} disabled={!isAdmin}>
										{YesNoChoice.map(x => <option key={x.value.toString()} value={x.value.toString()}>{x.text}</option>)}
									</Form.Select>
									{!!localErrors.allow_sso ? <Form.Control.Feedback type="invalid">{localErrors.allow_sso}</Form.Control.Feedback> : null}
								</Form.Group>
							</Col>
							{formData.reg_enabled ? <Col xs={12} sm={6} md={4}>
								<Form.Group className="mb-3" controlId="type">
									<Form.Label>{$t("Service registration application template")}</Form.Label>
									<Form.Select value={formData.reg_template} onChange={e => setField(e.target.value, "reg_template")} isInvalid={!!localErrors.reg_template} disabled={!isAdmin}>
										{!formData.reg_template ? <option value=""></option> : null}
										{ServiceTypes.map(x => <option key={x.name} value={x.name}>{x.title}</option>)}
									</Form.Select>
									{!!localErrors.reg_template ? <Form.Control.Feedback type="invalid">{localErrors.reg_template}</Form.Control.Feedback> : null}
								</Form.Group>
							</Col> : null}
							{formData.reg_enabled ? <Col xs={12} sm={6} md={4}>
								<Form.Group className="mb-3" controlId="type">
									<Form.Label>{$t("Service mail template")}</Form.Label>
									<Form.Select value={formData.mail_template} onChange={e => setField(e.target.value, "mail_template")} isInvalid={!!localErrors.mail_template} disabled={!isAdmin}>
										{!formData.mail_template ? <option value=""></option> : null}
										{ServiceTypes.map(x => <option key={x.name} value={x.name}>{x.title}</option>)}
									</Form.Select>
									{!!localErrors.mail_template ? <Form.Control.Feedback type="invalid">{localErrors.mail_template}</Form.Control.Feedback> : null}
								</Form.Group>
							</Col> : null}
						</Row>
					</FormSection>
					<FormSection title={$t("Main data")} required={false}>
						<Row>
							<Col xs={12} className="mb-3">
								<div className="form-label-upper">{$t("Login methods")}</div>
								{AuthTypes.map(x => <Form.Check
									key={x.code}
									checked={formData.service_auths.indexOf(x.code) >= 0}
									type="switch"
									inline
									label={$t(x.name)}
									onChange={e => setStringArr(e.target.checked, "service_auths", x.code)}
								/>)}
							</Col>
							{formData.reg_enabled ? <React.Fragment>
								<Col xs={12} sm={6} md={4}>
									<Form.Group className="mb-3" controlId="type">
										<Form.Label>Registreerimisavaldus tekib</Form.Label>
										<Form.Select value={formData.reg_application.toString()} onChange={e => setBooleanField(e.target.value, "reg_application")} isInvalid={!!localErrors.reg_application}>
											{YesNoChoice.map(x => <option key={x.value.toString()} value={x.value.toString()}>{x.text}</option>)}
										</Form.Select>
										{!!localErrors.reg_application ? <Form.Control.Feedback type="invalid">{localErrors.reg_application}</Form.Control.Feedback> : null}
									</Form.Group>
								</Col>
								{formData.reg_application ? <Col xs={12} sm={6} md={4}>
									<Form.Group className="mb-3" controlId="type">
										<Form.Label>Teenuse registreerimisel küsitakse allkirja</Form.Label>
										<Form.Select value={formData.reg_signature.toString()} onChange={e => setBooleanField(e.target.value, "reg_signature")} isInvalid={!!localErrors.reg_signature}>
											{YesNoChoice.map(x => <option key={x.value.toString()} value={x.value.toString()}>{x.text}</option>)}
										</Form.Select>
										{!!localErrors.reg_signature ? <Form.Control.Feedback type="invalid">{localErrors.reg_signature}</Form.Control.Feedback> : null}
									</Form.Group>
								</Col> : null}
								{formData.reg_application ? <Col xs={12} sm={6} md={4}>
									<Form.Group className="mb-3" controlId="type">
										<Form.Label>Teenuse registreerimisavaldus saadetakse DHS-i</Form.Label>
										<Form.Select value={formData.reg_to_dhs.toString()} onChange={e => setBooleanField(e.target.value, "reg_to_dhs")} isInvalid={!!localErrors.reg_to_dhs}>
											{YesNoChoice.map(x => <option key={x.value.toString()} value={x.value.toString()}>{x.text}</option>)}
										</Form.Select>
										{!!localErrors.reg_to_dhs ? <Form.Control.Feedback type="invalid">{localErrors.reg_to_dhs}</Form.Control.Feedback> : null}
									</Form.Group>
								</Col> : null}
								{formData.reg_application ? <Col xs={12} sm={6} md={4}>
									<Form.Group className="mb-3" controlId="type">
										<Form.Label>Teenuse tingimuste muutmisel küsitakse allkirja</Form.Label>
										<Form.Select value={formData.terms_signature.toString()} onChange={e => setBooleanField(e.target.value, "terms_signature")} isInvalid={!!localErrors.terms_signature}>
											{YesNoChoice.map(x => <option key={x.value.toString()} value={x.value.toString()}>{x.text}</option>)}
										</Form.Select>
										{!!localErrors.terms_signature ? <Form.Control.Feedback type="invalid">{localErrors.terms_signature}</Form.Control.Feedback> : null}
									</Form.Group>
								</Col> : null}
								{formData.reg_application ? <Col xs={12} sm={6} md={4}>
									<Form.Group className="mb-3" controlId="type">
										<Form.Label>Teenusele registreerimine käib nimekirja põhiselt</Form.Label>
										<Form.Select value={formData.reg_whitelist.toString()} onChange={e => setBooleanField(e.target.value, "reg_whitelist")} isInvalid={!!localErrors.reg_whitelist}>
											{YesNoChoice.map(x => <option key={x.value.toString()} value={x.value.toString()}>{x.text}</option>)}
										</Form.Select>
										{!!localErrors.reg_whitelist ? <Form.Control.Feedback type="invalid">{localErrors.reg_whitelist}</Form.Control.Feedback> : null}
										</Form.Group>
								</Col> : null}
								{formData.reg_application ? <Col xs={12} sm={6} md={4}>
									<Form.Group className="mb-3" controlId="type">
										<Form.Label>Teenusele saavad registreerida alaealised</Form.Label>
										<Form.Select value={formData.reg_underage.toString()} onChange={e => setBooleanField(e.target.value, "reg_underage")} isInvalid={!!localErrors.reg_underage}>
											{YesNoChoice.map(x => <option key={x.value.toString()} value={x.value.toString()}>{x.text}</option>)}
										</Form.Select>
										{!!localErrors.reg_underage ? <Form.Control.Feedback type="invalid">{localErrors.reg_underage}</Form.Control.Feedback> : null}
										</Form.Group>
								</Col> : null}
								{formData.reg_application ? <Col xs={12} sm={6} md={4}>
									<Form.Group className="mb-3" controlId="type">
										<Form.Label>Teenuses saab taodelda eriõiguseid</Form.Label>
										<Form.Select value={formData.reg_special_rights.toString()} onChange={e => setBooleanField(e.target.value, "reg_special_rights")} isInvalid={!!localErrors.reg_special_rights}>
											{YesNoChoice.map(x => <option key={x.value.toString()} value={x.value.toString()}>{x.text}</option>)}
										</Form.Select>
										{!!localErrors.reg_special_rights ? <Form.Control.Feedback type="invalid">{localErrors.reg_special_rights}</Form.Control.Feedback> : null}
										</Form.Group>
								</Col> : null}
								<Col xs={12} sm={6} md={4}>
									<Form.Group className="mb-3" controlId="type">
										<Form.Label>Teenuse loobumisavaldus tekib</Form.Label>
										<Form.Select value={formData.cancel_enabled.toString()} onChange={e => setBooleanField(e.target.value, "cancel_enabled")} isInvalid={!!localErrors.cancel_enabled}>
											{YesNoChoice.map(x => <option key={x.value.toString()} value={x.value.toString()}>{x.text}</option>)}
										</Form.Select>
										{!!localErrors.cancel_enabled ? <Form.Control.Feedback type="invalid">{localErrors.cancel_enabled}</Form.Control.Feedback> : null}
									</Form.Group>
								</Col>
								{formData.cancel_enabled ? <Col xs={12} sm={6} md={4}>
									<Form.Group className="mb-3" controlId="type">
										<Form.Label>Teenuse loobumisavaldusele on vaja allkirja</Form.Label>
										<Form.Select value={formData.cancel_signature.toString()} onChange={e => setBooleanField(e.target.value, "cancel_signature")} isInvalid={!!localErrors.cancel_signature}>
											{YesNoChoice.map(x => <option key={x.value.toString()} value={x.value.toString()}>{x.text}</option>)}
										</Form.Select>
										{!!localErrors.cancel_signature ? <Form.Control.Feedback type="invalid">{localErrors.cancel_signature}</Form.Control.Feedback> : null}
									</Form.Group>
								</Col> : null}
								{formData.cancel_enabled ? <Col xs={12} sm={6} md={4}>
									<Form.Group className="mb-3" controlId="type">
										<Form.Label>Teenuse loobumisavaldusel saadetakse DHS-i</Form.Label>
										<Form.Select value={formData.cancel_to_dhs.toString()} onChange={e => setBooleanField(e.target.value, "cancel_to_dhs")} isInvalid={!!localErrors.cancel_to_dhs}>
											{YesNoChoice.map(x => <option key={x.value.toString()} value={x.value.toString()}>{x.text}</option>)}
										</Form.Select>
										{!!localErrors.cancel_to_dhs ? <Form.Control.Feedback type="invalid">{localErrors.cancel_to_dhs}</Form.Control.Feedback> : null}
									</Form.Group>
								</Col> : null}
								<Col xs={12} sm={6} md={4}>
									<Form.Group className="mb-3" controlId="emailSmtp">
										<Form.Label>Andmed aeguvad pärast viimast muutmist (päevades)</Form.Label>
										<Form.Control value={formData.data_expire_days} onChange={e => setField(e.target.value, "data_expire_days")} isInvalid={!!localErrors.data_expire_days} />
										{!!localErrors.data_expire_days ? <Form.Control.Feedback type="invalid">{localErrors.data_expire_days}</Form.Control.Feedback> : null}
									</Form.Group>
								</Col>
								<Col xs={12} sm={6} md={4}>
									<Form.Group className="mb-3" controlId="type">
										<Form.Label>Kasutaja andmete muutmisel saadetakse registreerimisavaldus DHS-i</Form.Label>
										<Form.Select value={formData.user_changes_to_dhs.toString()} onChange={e => setBooleanField(e.target.value, "user_changes_to_dhs")} isInvalid={!!localErrors.user_changes_to_dhs}>
											{YesNoChoice.map(x => <option key={x.value.toString()} value={x.value.toString()}>{x.text}</option>)}
										</Form.Select>
										{!!localErrors.user_changes_to_dhs ? <Form.Control.Feedback type="invalid">{localErrors.user_changes_to_dhs}</Form.Control.Feedback> : null}
									</Form.Group>
								</Col>
								<Col xs={12} className="mb-3">
									<div className="form-label-upper">Keeled</div>
									{formData.all_locales.map(x => <Form.Check
										key={x.id}
										checked={formData.service_locales.indexOf(x.id) >= 0}
										type="switch"
										inline
										label={x.locale}
										onChange={e => setNumberArr(e.target.checked, "service_locales", x.id)}
									/>)}
								</Col>
							</React.Fragment> : null}
						</Row>
					</FormSection>
					{renderRegFields()}
					<FormSection title={$t("Email Connection Settings")} required={false}>
						<Row>
							<Col xs={12} sm={6} md={4}>
								<Form.Group className="mb-3" controlId="emailSmtp">
									<Form.Label>{$t("Email Smtp")}</Form.Label>
									<Form.Control value={formData.email_config?.emailSmtp} onChange={e => setEmailField(e.target.value, "emailSmtp")} isInvalid={!!localErrors.emailSmtp} />
									{!!localErrors.emailSmtp ? <Form.Control.Feedback type="invalid">{localErrors.emailSmtp}</Form.Control.Feedback> : null}
								</Form.Group>
							</Col>
							<Col xs={12} sm={6} md={4}>
								<Form.Group className="mb-3" controlId="emailFromEmail">
									<Form.Label>{$t("Email From")}</Form.Label>
									<Form.Control value={formData.email_config.emailFromEmail} onChange={e => setEmailField(e.target.value, "emailFromEmail")} isInvalid={!!localErrors.emailFromEmail} />
									{!!localErrors.emailFromEmail ? <Form.Control.Feedback type="invalid">{localErrors.emailFromEmail}</Form.Control.Feedback> : null}
								</Form.Group>
							</Col>
							<Col xs={12} sm={6} md={4}>
								<Form.Group className="mb-3" controlId="emailSmtpPort">
									<Form.Label>{$t("Email Smtp Port")}</Form.Label>
									<Form.Control type="number" value={formData.email_config.emailSmtpPort} onChange={e => setEmailField(e.target.value, "emailSmtpPort")} isInvalid={!!localErrors["email_config.EmailSmtpPort"]} />
									{!!localErrors["email_config.EmailSmtpPort"] ? <Form.Control.Feedback type="invalid">{localErrors["email_config.EmailSmtpPort"]}</Form.Control.Feedback> : null}
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col xs={12} sm={6} md={4}>
								<Form.Group className="mb-3" controlId="emailFromName">
									<Form.Label>{$t("Email Sender Name")}</Form.Label>
									<Form.Control value={formData.email_config.emailFromName} onChange={e => setEmailField(e.target.value, "emailFromName")} isInvalid={!!localErrors.emailFromName} />
									{!!localErrors.emailFromName ? <Form.Control.Feedback type="invalid">{localErrors.emailFromName}</Form.Control.Feedback> : null}
								</Form.Group>
							</Col>
							<Col xs={12} sm={6} md={4}>
								<Form.Group className="mb-3" controlId="cc">
									<Form.Label>{$t("Email Smtp password")}</Form.Label>
									<Form.Control value={formData.email_config.emailFromPassword} onChange={e => setEmailField(e.target.value, "emailFromPassword")} isInvalid={!!localErrors.emailFromPassword} />
									{!!localErrors.emailFromPassword ? <Form.Control.Feedback type="invalid">{localErrors.emailFromPassword}</Form.Control.Feedback> : null}
								</Form.Group>
							</Col>
						</Row>
					</FormSection>
					<FormSection title={$t("SIERRA related data")} required={false}>
						<Row>
							<Col xs={12} sm={6} md={4}>
								<Form.Group className="mb-3" controlId="name">
									<Form.Label>{$t("Username prefix")}</Form.Label>
									<Form.Control value={formData.sierra_prefix} onChange={e => setField(e.target.value, "sierra_prefix")} isInvalid={!!localErrors.sierra_prefix} />
									{!!localErrors.sierra_prefix ? <Form.Control.Feedback type="invalid">{localErrors.sierra_prefix}</Form.Control.Feedback> : null}
								</Form.Group>
							</Col>
							<Col xs={12} sm={6} md={4}>
								<Form.Group className="mb-3" controlId="name">
									<Form.Label>{$t("Customer group")}</Form.Label>
									<Form.Control value={formData.sierra_group} onChange={e => setField(e.target.value, "sierra_group")} isInvalid={!!localErrors.sierra_group} />
									{!!localErrors.sierra_group ? <Form.Control.Feedback type="invalid">{localErrors.sierra_group}</Form.Control.Feedback> : null}
								</Form.Group>
							</Col>
							{formData.reg_enabled ? <Col xs={12} sm={6} md={4}>
								<Form.Group className="mb-3" controlId="name">
									<Form.Label>{$t("Minor customer group")}</Form.Label>
									<Form.Control value={formData.sierra_underage_group} onChange={e => setField(e.target.value, "sierra_underage_group")} isInvalid={!!localErrors.sierra_underage_group} />
									{!!localErrors.sierra_underage_group ? <Form.Control.Feedback type="invalid">{localErrors.sierra_underage_group}</Form.Control.Feedback> : null}
								</Form.Group>
							</Col> : null}
						</Row>
					</FormSection>
					<FormSection title={$t("Service related applications")} required={false}>
						{formData.applications.map((x, i) =>
							<ApplicationRow
								key={x + "_" + i}
								index={i}
								app={x}
								allClassifiers={allClassifiers}
								localErrors={localErrors}
								regEnabled={formData.reg_enabled}
								onChange={(e) => setApplication(i, e)}
								onDelete={() => deleteApplication(i)}
							/>)}
						<Row>
							<Col xs={12} style={{ textAlign: "right" }}>
								<Button variant="primary" onClick={() => addApplication()}>Lisa uus rakendus</Button>
							</Col>
						</Row>
					</FormSection>
					{renderTerms()}
					{renderSysNotif()}
					{renderInfoNotif()}
					{confirmModal.open ? <ConfirmModal
						show={confirmModal.open}
						title={confirmModal.title}
						message={confirmModal.message}
						onCancel={() => setConfirmModal({ open: false, title: "", message: "" })}
						onOk={() => saveClick(false)}
					/> : null}
				</Form>
			</Container>
		</div>
	</div>);
}

export default ServiceEditPage;
